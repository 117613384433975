import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { NextPage } from 'next'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { GuestLayout, MetaTags } from '@root/layouts'
import PlaceholderLoader from '@root/components/Loader/PlaceholderLoader'
import { ENV } from '@root/config'
import LandingPage from '../experiences/Landing'

const IndexPage: NextPage = () => {
  const router = useRouter()
  const title = 'Dashboard'
  const isProduction = ENV.APP_ENV === 'production'
  const tempToken = router.query.t as string
  // redirect to dashboard if user is logged in
  useEffect(() => {
    if (router.pathname === UNPROTECTED_PAGES_PATHS.root && isProduction) {
      const path = tempToken
        ? `${UNPROTECTED_PAGES_PATHS.login}/?t=${tempToken}`
        : UNPROTECTED_PAGES_PATHS.login
      router.push(path)
    }
  })

  return (
    <>
      {isProduction ? (
        <>
          <MetaTags title={title} />
          <PlaceholderLoader />
        </>
      ) : (
        <GuestLayout title={title}>
          <LandingPage />
        </GuestLayout>
      )}
    </>
  )
}

export default IndexPage
