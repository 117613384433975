const LANDING_LABEL = {
  title: 'Easyyy and secure access to all your money matters. testinggggg',
  description:
    'Personalized tools, tips, and offers to control every money moment in your life.',
  sign_in: 'Sign in',
  dont_have_account: "Don't have an account?",
  signup_for_free: 'Sign up for free',
}

export default LANDING_LABEL
