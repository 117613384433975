import React from 'react'
import Cookies from 'js-cookie'
import { NextPage } from 'next'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { GuestLayout } from '@root/layouts'
import { ENV } from '@root/config'
import { useRemoteConfig } from '@root/hooks'
import { UNPROTECTED_PAGES_PATHS } from '@root/constants'
import { useAuthStore } from '@root/store'
import { REMOTE_CONFIG_FEATURE_FLAG } from '@root/constants/featureFlagNames'
import LANDING_LABEL from '../Landing/labels'
import {
  Container,
  HeaderContainer,
  HeaderLogo,
  HeaderButtonsView,
  HeaderSigninButton,
  HeaderSignupButton,
  DetailContainerView,
  CenteredView,
  LeftDetailView,
  MainTitle,
  DescriptionTitle,
  BottomButtonContainer,
  SigninButton,
  SignupContainerView,
  NoAccountLabel,
  SignupLink,
  RightDetailView,
  MobileViewImage,
  DesktopViewImage,
} from './index.styles'
import headerLogo from 'public/assets/svgs/ml-logo-white.svg'
import dashboardMobile from 'public/assets/svgs/ml-dashboard-mobile.svg'
import dashboardDesktop from 'public/assets/svgs/ml-dashboard-desktop-full-image.svg'

const LandingPage: NextPage = () => {
  const router = useRouter()
  const { treatment } = useRemoteConfig(
    REMOTE_CONFIG_FEATURE_FLAG.IAM.loginRollout
  )
  const [isLoading, setIsLoading] = React.useState(false)

  const iamRedirect = treatment?.asBoolean()
  const signInWithRedirect = useAuthStore((state) => state.signInWithRedirect)
  const onClickSignin = () => {
    setIsLoading(true)
    const iamCookie = Cookies.get('enable_iam')
    const shouldRedirect =
      iamRedirect || (iamCookie !== undefined && iamCookie === 'true')
    if (shouldRedirect) {
      signInWithRedirect()
    } else {
      router.push(UNPROTECTED_PAGES_PATHS.login)
    }
  }

  return (
    <GuestLayout title="Log In">
      <Container>
        <HeaderContainer>
          <HeaderLogo>
            <Image
              src={headerLogo}
              alt="MoneyLion Logo"
              className="moneylion-logo"
              width={180}
              height={40}
            />
          </HeaderLogo>
          <HeaderButtonsView>
            <HeaderSigninButton
              size="large"
              onClick={onClickSignin}
              style={{ color: 'white' }}
            >
              {LANDING_LABEL.sign_in}
            </HeaderSigninButton>
            <HeaderSignupButton
              onClick={() => router.push(`${ENV.ONBOARDING_WEB_URL}/signup}`)}
            >
              {LANDING_LABEL.signup_for_free}
            </HeaderSignupButton>
          </HeaderButtonsView>
        </HeaderContainer>
        <DetailContainerView>
          <LeftDetailView>
            <CenteredView>
              <MainTitle size="large">{LANDING_LABEL.title}</MainTitle>
              <DescriptionTitle>{LANDING_LABEL.description}</DescriptionTitle>
              <BottomButtonContainer>
                <SigninButton
                  type="submit"
                  onClick={onClickSignin}
                  loading={isLoading}
                >
                  {LANDING_LABEL.sign_in}
                </SigninButton>
                <SignupContainerView>
                  <NoAccountLabel>
                    {LANDING_LABEL.dont_have_account}
                  </NoAccountLabel>
                  <SignupLink
                    href={`${ENV.ONBOARDING_WEB_URL}/signup`}
                    style={{ color: 'white' }}
                  >
                    {LANDING_LABEL.signup_for_free}
                  </SignupLink>
                </SignupContainerView>
              </BottomButtonContainer>
            </CenteredView>
          </LeftDetailView>
          <RightDetailView>
            <MobileViewImage>
              <Image
                src={dashboardMobile}
                alt="MoneyLion Dashboard Mobile"
                className="moneylion-logo-mobile"
                width={375}
                height={311}
              />
            </MobileViewImage>
            <DesktopViewImage>
              <Image
                width={1028}
                height={708}
                src={dashboardDesktop}
                alt="MoneyLion Dashboard Desktop"
              />
            </DesktopViewImage>
          </RightDetailView>
        </DetailContainerView>
      </Container>
    </GuestLayout>
  )
}

export default LandingPage
