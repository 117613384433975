import Link from 'next/link'
import styled from 'styled-components'
import { Button, Label, theme, Display } from '@moneylion/mlds-web'

const MAX_TABLET_WIDTH = '1440px'
// Need to separate this from conflicting width of iPad-mini
const DESKTOP_MIN_WIDTH = '1025px'

const MAX_WIDTH = '2300px'

// Main Container View
export const Container = styled.div`
  background-color: ${({ theme }) => theme.color?.BACKGROUND_CONTENT_DARK};
  width: 100%;
  height: 100%;
  position: fixed;
  flex-direction: row;
  overflow: scroll;
  overflow-x: hidden;
`

/**
 * Header Container View that contains
 * Header Logo, Header Buttons (for desktop mode)
 */
export const HeaderContainer = styled.div`
  height: 74px;
  border-bottom: 1px solid ${({ theme }) => theme.color?.BORDER_ON_COLOR};
  display: flex;

  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    width: 100%;
  }

  // Exceeding the Screen size
  @media only screen and (min-width: ${MAX_WIDTH}) {
    justify-content: center;
    width: ${MAX_WIDTH};
    margin: 0 auto;
  }
`

export const HeaderLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 107px;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    justify-content: center;
    margin-left: 0px;
    width: 100%;
  }

  // Special Case for Bigger Size Table than breakpoint
  @media only screen and (min-width: ${theme.breakpoints
      ?.tablet}) and (max-width: ${MAX_TABLET_WIDTH}) {
    margin-left: 40px;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 70px;
  }

  // Exceeding the Screen size
  @media only screen and (min-width: ${MAX_WIDTH}) {
    display: flex;
    margin-left: 0px;
    justify-content: flex-start;
  }
`

export const HeaderButtonsView = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: 100px;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  // Special Case for Bigger Size Table than breakpoint
  @media only screen and (min-width: ${theme.breakpoints
      ?.tablet}) and (max-width: ${MAX_TABLET_WIDTH}) {
    margin-right: 50px;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  // Exceeding the Screen size
  @media only screen and (min-width: ${MAX_WIDTH}) {
    display: flex;
    margin-right: 0px;
    justify-content: flex-end;
  }
`
export const HeaderSigninButton = styled(Label)`
  display: flex;
  margin-left: 20px;
  margin-right: 32px;
  justify-content: flex;

  // Tablet
  @media only screen (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  :hover {
    cursor: pointer;
  }
`

export const HeaderSignupButton = styled(Button)`
  font-size: 14px;
  height: 36px;
  min-height: 36px;

  // Tablet
  @media only screen (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    display: none;
  }
`

/**
 * Detail Container View:
 * This will make the screen responsive based on desktop or mobile
 */
export const DetailContainerView = styled.div`
  // Desktop
  @media only screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
    display: flex;
  }

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    flex-direction: row;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    flex-direction: row;
  }

  // Exceeding the Screen size
  @media only screen and (min-width: ${MAX_WIDTH}) {
    justify-content: center;
  }
`
/**
 * Left Detail view Contains:
 * Tile, Description, and Buttons
 */
export const LeftDetailView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    justify-content: center;
  }
`

/**
 * Container of MLDashboard SVG For Mobile
 */
export const MobileViewImage = styled.div`
  .moneylion-logo-mobile {
    @media only screen and (min-width: ${theme.breakpoints?.tablet}) {
      display: none;
    }

    // Tablet
    @media only screen and (min-width: ${theme.breakpoints?.mobile}) and (max-width: ${theme.breakpoints?.tablet}) {
      display: flex;
      margin-top: 20px;
      width: 100vw;
      height 55vh;
    }
  }
`

/**
 * Right Detail View Contains:
 * Mobile and Desktop Svg Images. Responsive when comes to mobile
 */
export const RightDetailView = styled.div`
  display: flex;
  margin-top: 48px;
  justify-content: center;
`

/**
 * Centered View Contains:
 * Title, Description and Buttons.
 */
export const CenteredView = styled.div`
  flex-direction: row;
  align-items: center;
  margin-left: 100px;
  margin-right: 20px;

  @media only screen and (min-width: ${theme.breakpoints
      ?.tablet}) and (max-width: ${MAX_TABLET_WIDTH}) {
    margin-left: 30px;
    width: 45vw;
  }

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 50px;
    justify-content: center;
    width: 90%;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    flex-direction: row;
    width: 100%;
    margin-left: 0px;
    margin-right: 0px;
    align-items: center;
    margin-top: 30px;
    justify-content: center;
  }
`

export const MainTitle = styled(Display).attrs({
  className: 'mt-6 px-2',
})`
  display: flex;
  color: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_PRIMARY};
  font-size: 60px;
  font-weight: 600px;
  line-height: 72px;
  text-align: left;
  margin: 0;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    justify-content: center;
    text-align: center;

    // Specific to Smaller Screen Tablets
    @media only screen and (min-width: 740px) and (max-height: 768px) {
      font-size: 40px;
      font-weight: 600px;
      line-height: 55px;
    }
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    font-size: 36px;
    line-height: 44px;
    justify-content: center;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
  }
`

export const DescriptionTitle = styled(Label).attrs({
  className: 'mt-3 px-2',
})`
  display: flex;
  color: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_PRIMARY};
  font-weight: 400;
  font-size: 24px;
  line-height: 28px;
  text-align: left;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    margin-top: 30px;
    justify-content: center;
    text-align: center;

    // Specific to Smaller Screen Tablets
    @media only screen and (min-width: 740px) and (max-height: 768px) {
      font-size: 20px;
      font-weight: 600px;
      line-height: 20px;
      justify-content: center;
      text-align: center;
    }
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    justify-content: center;
    margin-left: 24px;
    margin-right: 24px;
  }
`

export const BottomButtonContainer = styled.div`
  // Desktop
  @media only screen and (min-width: ${DESKTOP_MIN_WIDTH}) {
    display: flex;
    margin-top: 20px;
  }

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    flex-direction: row;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    flex-direction: row;
  }
`

export const SigninButton = styled(Button).attrs({
  className: 'mt-6',
})`
  flex-direction: column;
  margin-left: 6px;
  width: 205px;
  height: 48px;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
  }
`

/**
 * Signup Container View Contains:
 * Responsive when it coems to mobile or desktop
 */
export const SignupContainerView = styled.div`
  flex-direction: row;
  margin-left: 24px;

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    margin-left: 0px;
  }
`
export const NoAccountLabel = styled(Label).attrs({
  className: 'mt-6',
})`
  display: flex;
  font-weight: 400;
  justify-content: center;
  color: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_SECONDARY};
`

export const SignupLink = styled(Link).attrs({
  className: 'underline',
})`
  display: flex;
  color: ${({ theme }) => theme.color?.CONTENT_ON_COLOR_PRIMARY};
  justify-content: flex-start;
  width: max-content;
  margin-right: auto;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    justify-content: center;
    margin-left: auto;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    justify-content: center;
    margin-left: auto;
  }
`

/**
 * DesktopView Image:
 * Container for SVG Image for Desktop
 */
export const DesktopViewImage = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 0;

  // Tablet
  @media only screen and (max-width: ${theme.breakpoints?.tablet}) {
    display: none;
  }

  // Mobile
  @media only screen and (max-width: ${theme.breakpoints?.mobile}) {
    display: none;
  }
`
